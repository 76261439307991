<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />
      <!-- メインコンテンツ -->
      <v-container fluid>
        <v-row class="center-align">
          <!-- 左側のサイドバー -->
          <v-col cols="12" md="3">
            <v-col>
              <v-sheet
                rounded="lg"
                :style="{
                  maxHeight: '1000px',
                  overflow: 'auto',
                }"
              >
                <div class="my-5" />
                <!-- サイドバー内容 -->
                <div class="centered-content">
                  <div class="author_label"></div>
                  <div class="author_img">
                    <img
                      decoding="async"
                      alt=""
                      src="../assets/neko.png"
                      class="avatar avatar-100 photo"
                      loading="lazy"
                    />
                  </div>
                  <strong>reomona</strong>
                  <p style="font-size: 0.7em">初級エンジニア</p>
                  <div class="profile-content"></div>
                </div>
                <AuthWidget
                  :user="user"
                  :signinPopup="handleSignin"
                  :logout="handleLogout"
                />
              </v-sheet>
            </v-col>
            <v-col>
            <v-sheet
              rounded="lg"
              :style="{
                maxHeight: '1000px',
                overflow: 'auto',
              }"
            >
              <div class="my-5" />
              <MdCatalog
                class="md-catalog"
                :editorId="id"
                :scrollElement="state.scrollElement"
                :mdHeadingId="mdHeadingId"
                @onClick="handleCatalogClick"
              />
              <div class="my-5" />
            </v-sheet></v-col>
          </v-col>

          <!-- メインコンテンツ -->
          <v-col cols="12" md="8" class="main-content">
            <section class="container-vertical-layout">
              <div class="not-auth">
                <MdPreview
                  class="md-preview"
                  v-model="content"
                  :editorId="id"
                  :mdHeadingId="mdHeadingId"
                  language="jp_JP"
                  preview-theme="github"
                />
              </div>
            </section>
          </v-col>

          <!-- 右側のサイドバー -->
          <v-col cols="12" md="3">
            <AdvertisementWidget />
            <div class="my-5" />
          </v-col>
        </v-row><FadeTopWidget />
      </v-container>
    </v-main>
  </v-app>
</template>


<script setup>
import { useRoute } from "vue-router";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";
import AdvertisementWidget from "@/components/AdvertisementWidget.vue";
import AuthWidget from "@/components/AuthWidget.vue";
import { MdPreview, MdCatalog } from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import { ref, computed } from "vue";
import { logout, signinPopup } from '@/utils/auth';
import { useCurrentUser } from "vuefire";
import FadeTopWidget from "@/components/FadeTopWidget.vue";

const id = `md-editor-about`; // 一意のエディタID
const mdHeadingId = (_text, _level, index) => `heading-${index}`;
const user = useCurrentUser();
const content = ref(`
## はじめに
新卒3年目
主にVue.JSを使って画面等のフロントエンドエンジニアで
プライベートではRubyやFlutterについて勉強していきたいと考えています！
その一環でサイトを立ち上げてみました

### 技術系
プログラミング言語: 
> Java、Dart

フレームワーク: 
> Vue.js、Flutter

Baas: 
> Firebase、Supabase

DB: 
> PostgreSQL

### 頑張って取った資格たち
- 基本情報技術者試験
- 応用情報技術者試験
- Oracle Certified Java Programmer, Silver SE 8
- Oracle Certified Java Programmer, Gold SE 11
  

## 最後に
経験が浅く、まだまだ知識不足なので、プログラミング学習や資格勉強も行っていきます！

`);
// テキストとスクロール要素の状態をリアクティブな変数として定義します
const state = {
  text: ref("# heading"), // デフォルトのエディターテキスト
  scrollElement: document.documentElement, // デフォルトのスクロール要素
};
const breadcrumbs = computed(() => {
  const route = useRoute();
  return route.meta.breadcrumbs || [];
});
const handleSignin = () => {
    signinPopup();
  };
const handleLogout = () => {
    logout();
  };
</script>

<style>
/* コード内の特定の部分を強調する例 */
.highlight {
  background-color: rgba(255, 255, 0, 0.2); /* 黄色の背景 */
  font-weight: bold; /* 太字に */
}
</style>