<template>
  <div @click="toggle" :class="['toggle-switch', { 'toggle-switch--on': isOn }]">
    <div class="toggle-switch__knob"></div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isOn = ref(props.modelValue);

    const toggle = () => {
      isOn.value = !isOn.value;
      emit('update:modelValue', isOn.value);
    };

    watch(
      () => props.modelValue,
      (newVal) => {
        isOn.value = newVal;
      }
    );

    return {
      isOn,
      toggle,
    };
  },
};
</script>

<style scoped>
.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch--on {
  background-color: #4caf50;
}

.toggle-switch__knob {
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.3s;
}

.toggle-switch--on .toggle-switch__knob {
  left: 27px;
}
</style>
