import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyB5T8X3WWc5Yq2EF4aX0Ohkiuv0OO6_g6Y",
    authDomain: "rmona-82ad3.firebaseapp.com",
    projectId: "rmona-82ad3",
    storageBucket: "rmona-82ad3.appspot.com",
    messagingSenderId: "982609142114",
    appId: "1:982609142114:web:4b6cd4ff3ac85bbafe4c3b",
    measurementId: "G-N9D0XYQXWF"
};

// firebaseConfigの値を元にfirebaseの初期化
const app = initializeApp(firebaseConfig);
// アナリティクスの初期化
getAnalytics(app);

// 初期化された Firebase アプリをエクスポート
export default app; // 他のファイルで参照できるようにする

export const firebaseApp = initializeApp(firebaseConfig);