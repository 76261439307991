<template>
  <div class="custom-header">
    <!-- サイトのタイトル -->
    <div class="header">
      <a href="/" class="header-title">monocraft</a>
    </div>

    <!-- ヘッダーのスペース -->
    <div class="header-spacer" />

    <!-- ナビゲーションアイテム -->
    <div class="header-menu">
      <AuthWidget :user="user" :signinPopup="signinPopup" :logout="logout" />
      <!-- カスタムボタン -->
    </div>
  </div>
</template>

<script setup>
import AuthWidget from "@/components/AuthWidget.vue";

const logout = () => {
  console.log("Logout clicked");
};
</script>

<style scoped>
.custom-header {
  background-color: #999999; /* 背景色 */
  color: white; /* 文字色 */
  height: 50px; /* ヘッダーの高さ */
  display: flex; /* フレックスレイアウト */
  align-items: center; /* 垂直方向で中央揃え */
  justify-content: space-between; /* 水平方向でスペースを分ける */
  padding: 0 16px; /* 左右に余白 */
}

.header-title {
  font-size: 1.5em; /* タイトルのサイズ */
}

.header-menu {
  display: flex; /* フレックスレイアウト */
  gap: 16px; /* メニューアイテムの間にギャップを追加 */
}

.header-spacer {
  flex-grow: 1; /* スペーサー */
}

.header a {
  color: white;
  text-decoration: none;
}
</style>
