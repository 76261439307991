<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <v-container>
        <v-row justify="center">
          <v-col class="col-2" :cols="getColCount2">
            <!-- 1:3の比率 -->
            <v-sheet
              min-height="70vh"
              rounded="lg"
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div
                style="
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <div class="my-5" />
                <v-radio-group v-model="postType" row>
                  <v-radio label="Post" value="post"></v-radio>
                  <v-radio label="Notice" value="notice"></v-radio>
                </v-radio-group>
                <div>
                  <v-row align="center" justify="space-between">
                    <v-col cols="auto">
                      <p>Archive</p>
                    </v-col>
                    <v-col cols="auto">
                      <ToggleSwitchWidget v-model="archive_flag" />
                    </v-col>
                  </v-row>
                </div>
                <v-col :cols="12">
                  <v-text-field
                    label="title"
                    prepend-icon="$vuetify"
                    v-model="title"
                    variant="solo-filled"
                    clearable
                /></v-col>
                <v-col :cols="12">
                  <v-text-field
                    label="category (Half-width space delimiter)"
                    prepend-icon="$vuetify"
                    v-model="category"
                    variant="solo-filled"
                    clearable
                /></v-col>
                <MdEditor
                  v-model="content"
                  language="jp_JP"
                  preview-theme="github"
                />
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row justify="center">
          <!-- 登録ボタン -->
          <v-btn
            v-if="noticeId === 'n' && postType == 'notice'"
            @click="register"
            class="mr-4"
            >Notice投稿</v-btn
          >
          <!-- 編集ボタン -->
          <v-btn
            v-if="noticeId !== 'n' && postType == 'notice'"
            @click="register"
            class="mr-4"
            >編集</v-btn
          >
          <!-- 削除ボタン -->
          <v-btn
            v-if="noticeId !== 'n' && postType == 'notice'"
            @click="deleter"
            >削除</v-btn
          >
          <!-- 登録ボタン -->
          <v-btn
            v-if="postId === 'p' && postType == 'post'"
            @click="register"
            class="mr-4"
            >Post投稿</v-btn
          >
          <!-- 編集ボタン -->
          <v-btn
            v-if="postId !== 'p' && postType == 'post'"
            @click="register"
            class="mr-4"
            >編集</v-btn
          >
          <!-- 削除ボタン -->
          <v-btn v-if="postId !== 'p' && postType == 'post'" @click="deleter"
            >削除</v-btn
          >
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { MdEditor } from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import { ref, onMounted, computed } from "vue";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getPostById, getNoticeById } from "@/firebase/firebase_function";
import app from "@/firebase/firebase"; // Firebase の初期化
import { useRoute } from "vue-router";
import router from "@/router";
import ToggleSwitchWidget from "./ToggleSwitchWidget.vue";

// Firestore インスタンスの取得
const db = getFirestore(app);

// データの状態管理
const title = ref("");
const category = ref("");
const content = ref("");
const archive_flag = ref(false);
const postType = ref("");
const post = ref({});
const notice = ref({});
const route = useRoute();
const fullPath = route.fullPath; // フルパスを取得
const parts = fullPath.split("/"); // URL を '/' で分割

// URL の形式に応じて postId または noticeId を設定
const admin = parts[1] === "admin" ? true : false;
const postId = parts[2] === "p" ? parts[3] : "p";
const noticeId = parts[2] === "n" ? parts[3] : "n";
const type = parts[2];
const isWideScreen = ref(false);
const getColCount2 = computed(() => {
  // 700px以上であれば12カラム、それ以下であれば6カラム
  return isWideScreen.value ? 0 : 0;
});
onMounted(async () => {
  postType.value = type;
  // データの取得関数
  if (type === "p") {
    try {
      post.value = await getPostById(postId);
      title.value = post.value.title;
      category.value = post.value.category;
      content.value = post.value.content;
      archive_flag.value = post.value.archive_flag;
    } catch (error) {
      console.error("投稿の取得中にエラーが発生しました:", error);
    }
  } else if (type === "n") {
    try {
      notice.value = await getNoticeById(noticeId);
      title.value = notice.value.title;
      content.value = notice.value.content;
      archive_flag.value = post.value.archive_flag;
    } catch (error) {
      console.error("お知らせの取得中にエラーが発生しました:", error);
    }
  }
});

// データの登録関数、編集関数
const register = async () => {
  if (postType.value == "post") {
    // フィールドが空白ではないかをチェック
    if (
      !title.value.trim() ||
      !category.value.trim() ||
      !content.value.trim()
    ) {
      alert(
        "タイトル、カテゴリ、コンテンツのいずれかが空白です。送信できません。"
      );
      return; // フィールドが空白の場合、処理を中止
    }
  } else {
    if (!title.value.trim() || !content.value.trim()) {
      alert("タイトル、コンテンツのいずれかが空白です。送信できません。");
      return; // フィールドが空白の場合、処理を中止
    }
  }
  if (postType.value == "post") {
    if ((postId === "p" && type === "p") || admin) {
      try {
        // 新しいドキュメント参照を生成し、Firestore に追加
        const newDocRef = doc(collection(db, "posts"));
        await setDoc(newDocRef, {
          title: title.value,
          category: category.value,
          content: content.value,
          archive_flag: archive_flag.value,
          id: newDocRef.id, // 自動生成された ID を追加
          created_at: serverTimestamp(), // 作成時刻を追加
          update_at: serverTimestamp(), // 更新時刻を追加
        });
        // 成功メッセージをユーザーに表示
        alert(`記事を投稿しました。ID: ${newDocRef.id}`); // 生成された ID を表示
        router.push("/");
      } catch (error) {
        // エラーが発生したときの処理
        alert("データ送信時にエラーが発生しました: " + error.message);
      }
    } else {
      try {
        // 新しいドキュメント参照を生成し、Firestore に追加
        const docRef = doc(collection(db, "posts"), postId);
        await updateDoc(docRef, {
          title: title.value,
          category: category.value,
          content: content.value,
          archive_flag: archive_flag.value,
          update_at: serverTimestamp(), // 更新時刻を追加
        });
        // 成功メッセージをユーザーに表示
        alert(`記事を編集しました。ID: ${docRef.id}`); // 生成された ID を表示
        router.push("/");
      } catch (error) {
        // エラーが発生したときの処理
        alert("データ編集時にエラーが発生しました: " + error.message);
      }
    }
  } else {
    if ((postId === "n" && type === "n") || admin) {
      try {
        // 新しいドキュメント参照を生成し、Firestore に追加
        const newDocRef = doc(collection(db, "notices"));
        await setDoc(newDocRef, {
          title: title.value,
          content: content.value,
          archive_flag: archive_flag.value,
          id: newDocRef.id, // 自動生成された ID を追加
          created_at: serverTimestamp(), // 作成時刻を追加
          update_at: serverTimestamp(), // 更新時刻を追加
        });
        // 成功メッセージをユーザーに表示
        alert(`お知らせを投稿しました。ID: ${newDocRef.id}`); // 生成された ID を表示
        router.push("/notice");
      } catch (error) {
        // エラーが発生したときの処理
        alert("データ送信時にエラーが発生しました: " + error.message);
      }
    } else {
      try {
        // 新しいドキュメント参照を生成し、Firestore に追加
        const docRef = doc(collection(db, "notices"), noticeId);
        await updateDoc(docRef, {
          title: title.value,
          category: category.value,
          content: content.value,
          archive_flag: archive_flag.value,
          update_at: serverTimestamp(), // 更新時刻を追加
        });
        // 成功メッセージをユーザーに表示
        alert(`お知らせを編集しました。ID: ${docRef.id}`); // 生成された ID を表示
        router.push("/notice");
      } catch (error) {
        // エラーが発生したときの処理
        alert("データ編集時にエラーが発生しました: " + error.message);
      }
    }
  }
};
const deleter = async () => {
  if (postType.value == "post") {
    try {
      // 新しいドキュメント参照を生成し、Firestore に追加
      const docRef = doc(collection(db, "posts"), postId);
      await deleteDoc(docRef, {
        id: postId,
      });
      // 成功メッセージをユーザーに表示
      alert(`記事を削除しました。ID: ${docRef.id}`); // 生成された ID を表示
      router.push("/");
    } catch (error) {
      // エラーが発生したときの処理
      alert("データ削除時にエラーが発生しました: " + error.message);
    }
  } else {
    try {
      // 新しいドキュメント参照を生成し、Firestore に追加
      const docRef = doc(collection(db, "notices"), noticeId);
      await deleteDoc(docRef, {
        id: noticeId,
      });
      // 成功メッセージをユーザーに表示
      alert(`お知らせを削除しました。ID: ${docRef.id}`); // 生成された ID を表示
      router.push("/");
    } catch (error) {
      // エラーが発生したときの処理
      alert("データ削除時にエラーが発生しました: " + error.message);
    }
  }
};
</script>

<style scoped>
/* 必要なスタイル設定 */
</style>