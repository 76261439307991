<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />
      <v-container>
        <v-row justify="center">
          <v-col class="col-1" :cols="getColCount1">
            <!-- 1:3の比率 -->
            <v-sheet
              rounded="lg"
              :style="{
                maxHeight: '1000px',
                overflow: 'auto',
              }"
            >
              <v-list>
                <v-divider class="my-2" />
                <v-col cols="">
                  <section class="sub-section">
                    <!-- Table of Contents -->
                    <v-divider class="my-2" />
                    <v-expansion-panels>
                      <v-expansion-panel title="目次">
                        <v-expansion-panel-text>
                          <!-- Table of Contents -->
                          <MdCatalog
                            class="md-catalog"
                            :editorId="id"
                            :scrollElement="state.scrollElement"
                            :mdHeadingId="mdHeadingId"
                            @onClick="handleCatalogClick"
                        /></v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </section>
                </v-col>
              </v-list>
              <v-list>
                <v-divider class="my-2" />
                <h2>その他の記事</h2>
                <v-col cols="">
                  <section class="sub-section">
                    <article
                      class="sub-post"
                      v-for="post in posts"
                      :key="post.id"
                    >
                      <figure>
                        <a
                          class="ArticleList_emoji__C9rT3"
                          :href="`/post/${post.id}`"
                          style="background: rgb(255, 255, 255)"
                          ><span class="Emoji_twemoji__hcxYF"
                            ><span
                              class="Emoji_twemojiImg__Oc6vR"
                              style="
                                background-image: url('https://asia-northeast1-zenn-dev-production.cloudfunctions.net/twemoji/🤖.svg');
                              "
                            ></span></span
                          ><span class="Emoji_nativeEmoji__GMBzX">🤖</span></a
                        >
                      </figure>
                      <h2 class="sub-post-title">{{ post.title }}</h2>
                      <p class="sub-post-lead">{{ post.content }}</p>
                      <p class="sub-post-category" :class="'post-category'">
                        {{ post.category }}
                      </p>
                    </article>
                  </section>
                </v-col>
              </v-list>
            </v-sheet>
          </v-col>

          <v-col class="col-2" :cols="getColCount2">
            <!-- 1:3の比率 -->
            <v-sheet
              min-height="70vh"
              rounded="lg"
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div
                id="content"
                style="
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  overflow-y: auto;
                "
                v-if="post.title"
              >
                <v-divider class="my-2" />
                <h1 class="post-title-detail">{{ post.title }}</h1>

                <v-chip-group v-if="post.category">
                  <v-chip
                    v-for="(category, index) in post.category.split(' ')"
                    :key="index"
                    :class="'post-category'"
                  >
                    {{ category }}
                  </v-chip> </v-chip-group
                ><v-divider class="my-2" />
                <v-row style="justify-content: flex-end">
                  <v-btn icon @click="heartClick">
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon @click="penClick">
                    <v-icon>mdi-lead-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="shareClick">
                    <v-icon>mdi-share</v-icon>
                  </v-btn>
                </v-row>
                <v-divider class="my-2" />
                <h4 class="post-date">
                  作成：{{ formatTimeStamp(post.created_at) }}
                </h4>
                <h4 class="post-date">
                  更新：{{ formatTimeStamp(post.update_at) }}
                </h4>
                <MdPreview
                  class="md-preview"
                  :mdHeadingId="mdHeadingId"
                  :editorId="id"
                  :modelValue="post.content"
                  :noHighlight="false"
                />
              </div>
              <div v-else-if="isLoading && post">
                <v-progress-circular
                  color="#f3948f"
                  indeterminate
                ></v-progress-circular>
              </div>
              <!-- 記事が見つからない場合のメッセージ -->
              <div v-else class="not-found-message">
                <p>以下の理由で記事が見つかりません。</p>
                <ul>
                  <li>存在しない記事</li>
                  <li>削除された記事</li>
                  <li>アーカイブされた記事</li>
                </ul>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { MdPreview, MdCatalog } from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import "@mdi/font/css/materialdesignicons.css";
import {
  getPostById,
  getPostByIdNoArchive,
  getPostsNoArchive,
  formatTimeStamp,
} from "@/firebase/firebase_function";
import router from "@/router";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";

const post = ref({});
const posts = ref([]);
const route = useRoute();
const postId = route.params.id;
const isWideScreen = ref(false);
const isLoading = ref(false);
const id = `md-editor-${postId}`; // 一意のエディタID
const mdHeadingId = (_text, _level, index) => `heading-${index}`;
const fullPath = route.fullPath; // フルパスを取得
const parts = fullPath.split("/"); // URL を '/' で分割
// URL の形式に応じて postId または noticeId を設定
const admin = parts[1] === "admin" ? true : false;
const heartClick = () => {
  router.push("/");
};
const penClick = () => {
  router.push(`/edit/p/${postId}`);
};
const shareClick = () => {
  router.push(`/edit/p/${postId}`);
};
const getColCount1 = computed(() => {
  // 700px以上であれば12カラム、それ以下であれば6カラム
  return isWideScreen.value ? 3 : 0;
});

const getColCount2 = computed(() => {
  // 700px以上であれば12カラム、それ以下であれば6カラム
  return isWideScreen.value ? 9 : 12;
});

const checkScreenWidth = () => {
  isWideScreen.value = window.innerWidth >= 700;
};

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenWidth);
});

onMounted(async () => {
  // 初回チェックとリスナー追加
  checkScreenWidth();
  window.addEventListener("resize", checkScreenWidth);
  try {
    isLoading.value = true;
    post.value = admin
      ? await getPostById(postId)
      : await getPostByIdNoArchive(postId);
    posts.value = await getPostsNoArchive();
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    console.error("投稿の取得中にエラーが発生しました:", error);
  }
});
// 計算プロパティでパンくずリストを定義
const breadcrumbs = computed(() => {
  const baseBreadcrumbs = route.meta.breadcrumbs || []; // metaからパンくずリストを取得
  // 新しいアイテムを追加
  const additionalItem = { name: post.value.title, href: `/post/${postId}` };
  return [...baseBreadcrumbs, additionalItem];
});

// 目次クリックイベントハンドラ
const handleCatalogClick = (e, item) => {
  e.preventDefault(); // デフォルトのクリック動作を無効化
  if (item) {
    console.log("Clicked on item:", item); // クリックされた目次項目をコンソールにログ出力
    const sectionId = item.id; // 目次項目のIDを取得
    const sectionElement = document.getElementById(sectionId); // 目次項目の要素を取得
    if (sectionElement) {
      // 目次項目に一意なIDを設定
      sectionElement.id = `catalog-item-${sectionId}`;

      // セクションが存在する場合は、そのセクションにスクロールする
      sectionElement.scrollIntoView({ behavior: "smooth" });

      // URLにアンカーを追加して、ブラウザの履歴を更新する
      router.push({ hash: sectionId });
    }
  }
};
// テキストとスクロール要素の状態をリアクティブな変数として定義します
const state = {
  text: ref("# heading"), // デフォルトのエディターテキスト
  scrollElement: document.documentElement, // デフォルトのスクロール要素
};
</script>

<style scoped>
.sub-section {
  grid-template-columns: 340px 340px;
  column-gap: 50px;
  row-gap: 100px;
}

.sub-thumbnail {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.sub-post {
  text-decoration: none;
  color: #000;
  margin-bottom: 80px;
  display: block;
}

.sub-post-category {
  text-align: left;
  margin-bottom: 15px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sub-post-title {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sub-post-lead {
  text-align: left;
  margin-bottom: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-title {
  margin-top: 25px;
  font-weight: bold;
}

.post-category {
  color: grey;
  font-size: 12px;
}

.col-1 {
  display: none;
  /* デフォルトで非表示にする */
}

@media (min-width: 450px) {
  .col-1 {
    display: block;
  }
}
</style>
