<template>
  <v-app id="inspire">
    <v-system-bar>
      <v-spacer></v-spacer>
      <v-icon>mdi-square</v-icon>
      <v-icon>mdi-circle</v-icon>
      <v-icon>mdi-triangle</v-icon>
    </v-system-bar>

    <v-navigation-drawer
      v-if="!isSmallScreen"
      v-model="drawer"
      app
      permanent
      z-index="0"
      class="custom-navigation-drawer"
    >
      <v-sheet class="pa-4" color="grey-lighten-4">
        <div class="author_img">
          <img
            decoding="async"
            alt=""
            src="../assets/neko.png"
            class="avatar avatar-100 photo"
          />
        </div>
        <div>{{ user?.displayName }}</div>
      </v-sheet>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="[icon, text, index] in links"
          :key="index"
          :prepend-icon="icon"
          :title="text"
          @click="sideClick(index)"
          link
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container class="py-4 px-4" fluid>
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <div v-if="sideItem === 0">
              <MarkDownEditor />
            </div>
            <div v-if="sideItem === 1">
              <HomePosts :admin="true"/>
            </div>
            <div v-if="sideItem === 2">
              <ProductPage />
            </div>
            <div v-if="sideItem === 3">
              <NoticePage :admin="true"/>
            </div>
          </v-col>
        </v-row>
        <v-bottom-navigation
          v-if="isSmallScreen"
          v-model="bottomNav"
          app
          class="custom-bottom-navigation"
        >
          <v-btn
            v-for="[icon, text, index] in links"
            :key="index"
            :title="text"
            @click="sideClick(index)"
          >
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useCurrentUser } from "vuefire";
import MarkDownEditor from "@/components/MarkDownEditor.vue";
import HomePosts from "@/components/HomePosts.vue";
import ProductPage from "@/components/ProductPage.vue";
import NoticePage from "@/components/NoticePage.vue";
import router from "@/router";

const links = [
  ["mdi-lead-pencil", "Edit", 0],
  ["mdi-newspaper", "Post", 1],
  ["mdi-file-multiple", "Product", 2],
  ["mdi-comment-processing-outline", "Notice", 3],
  ["mdi-reload", "Exit", 4],
];

const user = useCurrentUser();
const drawer = ref(true);
const bottomNav = ref(null);
const sideItem = ref(0);
const isSmallScreen = ref(window.innerWidth <= 500);

const sideClick = (index) => {
  sideItem.value = index;
  if (sideItem.value === 4) {
    router.push("/");
  }
};

const updateScreenSize = () => {
  isSmallScreen.value = window.innerWidth <= 500;
};

onMounted(() => {
  window.addEventListener("resize", updateScreenSize);
  updateScreenSize();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateScreenSize);
});
</script>

<style scoped>
.author_img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.custom-navigation-drawer {
  height: calc(100% - 20%) !important;
}

.custom-bottom-navigation {
  margin-bottom: 10%;
}
</style>
