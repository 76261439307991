<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <v-container fluid>
        <v-row justify="center" align="stretch">
          <!-- Project Details Column -->
          <v-col :cols="getColCount2" class="d-flex justify-center">
            <v-sheet
              min-height="70vh"
              rounded="lg"
              elevation="2"
              class="pa-5"
              width="100%"
            >
              <div class="projects-list">
                <v-container>
                  <h1 class="text-center">{{ project.name }}</h1> <h2 class="text-center">{{ project.description }}</h2>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-img
                        :src="project.image"
                        height="200px"
                        alt="Project Image"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

// ルートからプロジェクトIDを取得
const route = useRoute();
const projectId = route.params.id;

// プロジェクトの詳細情報
const project = ref({});
const isWideScreen = ref(false);

const getColCount2 = computed(() => {
  return isWideScreen.value ? 9 : 12;
});

const checkScreenWidth = () => {
  isWideScreen.value = window.innerWidth >= 700;
};

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenWidth);
});

onMounted(async () => {
  checkScreenWidth();
  window.addEventListener("resize", checkScreenWidth);
  const fetchedProject = await getProductById(projectId);
  project.value = fetchedProject;
});

const getProductById = (id) => {
  const productData = {
    1: {
      name: "EventLink",
      description: "イベント計画管理ツール",
      image: "../assets/neko.png",
      demo: "https://eventlink-6c64b.web.app/",
      technologyStack: ["Flutter", "Firebase", "Supabase"],
      repository: "https://github.com/example/eventlink",
    },
    2: {
      name: "個人開発管理ツール（仮）",
      description: "sakamona氏と制作予定",
      image: "https://example.com/product-b.jpg",
      demo: "https://example.com/product-b.jpg",
      technologyStack: ["Feature 1", "Feature 2"],
      repository: "https://github.com/example/individual-project",
    },
    // 他の商品データ
  };

  return productData[id] || null;
};
</script>

<style scoped>
.projects-list {
  padding: 20px;
}

.sub-post {
  margin-bottom: 20px;
}

.sub-post-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.sub-post-lead {
  color: #555;
}

.sub-post-category {
  color: #007bff;
}

.sub-thumbnail {
  object-fit: cover;
  width: 100%;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}
</style>
