import { signOut, signInWithPopup, GoogleAuthProvider, getAuth } from "firebase/auth"; // Firebase Authをインポート
// import { useFirebaseAuth } from "vuefire";
import router from "@/router";

const auth = getAuth();

// ログアウト関数
export const logout = async () => {
    try {
        await signOut(auth); // Firebase Authでログアウト
        alert("ログアウトしました");
    } catch (error) {
        alert("ログアウト時にエラーが発生しました:", error);
    }
};


const googleAuthProvider = new GoogleAuthProvider();
export const signinPopup = async () => {
    try {
        await signInWithPopup(auth, googleAuthProvider);
        router.push("/admin");
    } catch (error) {
        alert("ログイン時にエラーが発生しました:", error);
    }
};