<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />
      <v-container fluid>
        <v-row class="center-align">
          <!-- 左側のサイドバー -->
          <v-col cols="12" md="3">
            <v-sheet
              rounded="lg"
              :style="{
                maxHeight: '1000px',
                overflow: 'auto',
              }"
            >
              <div class="my-5" />
              <MdCatalog
                class="md-catalog"
                :editorId="id"
                :scrollElement="state.scrollElement"
                :mdHeadingId="mdHeadingId"
                @onClick="handleCatalogClick"
              />
              <div class="my-5" />
            </v-sheet>
          </v-col>
          <v-col cols="12" md="8" class="main-content">
            <section class="container-vertical-layout">
              <v-sheet
                min-height="70vh"
                rounded="lg"
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                "
              >
                <div class="my-5" />
                <div v-if="notification">
                  <h2>{{ notification.title }}</h2>
                  <div class="my-5" />
                  <v-btn icon @click="penClick">
                    <v-icon>mdi-lead-pencil</v-icon>
                  </v-btn>
                  <div class="my-5" />
                  <h4 class="post-date">
                    作成：{{ formatTimeStamp(notification.created_at) }}
                  </h4>
                  <h4 class="post-date">
                    更新：{{ formatTimeStamp(notification.update_at) }}
                  </h4>
                  <MdPreview
                    class="md-preview"
                    :mdHeadingId="mdHeadingId"
                    :editorId="id"
                    :modelValue="notification.content"
                    :noHighlight="false"
                    language="jp_JP"
                    preview-theme="github"
                  />
                </div>
                <div v-else>
                  <p>通知が見つかりません。</p>
                </div>
              </v-sheet>
            </section>
          </v-col>
        </v-row>
      </v-container>
    </v-main></v-app
  >
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { MdPreview, MdCatalog } from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import { getNoticeById, formatTimeStamp } from "@/firebase/firebase_function";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";
import router from "@/router";

const notification = ref({});
const route = useRoute();
const noticeId = route.params.id;
const fetchNotification = async () => {
  notification.value = await getNoticeById(noticeId);
};
// テキストとスクロール要素の状態をリアクティブな変数として定義します
const state = {
  text: ref("# heading"), // デフォルトのエディターテキスト
  scrollElement: document.documentElement, // デフォルトのスクロール要素
};
const id = `md-editor-${noticeId}`; // 一意のエディタID
const mdHeadingId = (_text, _level, index) => `heading-${index}`;
// 計算プロパティでパンくずリストを定義
const breadcrumbs = computed(() => {
  const baseBreadcrumbs = route.meta.breadcrumbs || []; // metaからパンくずリストを取得
  // 新しいアイテムを追加
  const additionalItem1 = {
    name: "Notice",
    href: `/notice/`,
  };
  const additionalItem2 = {
    name: notification.value.title,
    href: `/notice/${noticeId}`,
  };
  return [...baseBreadcrumbs, additionalItem1, additionalItem2];
});

const penClick = () => {
  router.push(`/edit/n/${noticeId}`);
};

onMounted(async () => {
  fetchNotification();
});
</script>